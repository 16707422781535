<template>
    <!-- main-area -->
    <section class="main-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <!-- sidebar -->
                    <TheSidebar/>
                    <!-- sidebar -->

                    <!-- right-body -->
                    <div class="audits-completed col-md-12">
                        <!-- Header -->
                        <TheHeader headerType = 'logo'/>
                        <!-- end header -->
                        <slot/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- main-area -->
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'

export default {
    name: 'DashboardContainer', 
    components: {
        TheSidebar,
        TheHeader,
    }
}
</script>